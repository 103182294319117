<perfect-scrollbar class="side-nav" sideNav>

<a href="">
    <div class="logo-sidenav">
        <img *ngIf="!fullImageUrl" src="assets/images/logo.svg" alt="">
        <img *ngIf="fullImageUrl" [src]="fullImageUrl" alt="">
    </div>
  </a>
 


    
        
  <ul class="ant-menu ant-menu-root ant-menu-inline side-nav-menu">
    <li class="ant-menu-submenu ant-menu-submenu-inline ant-menu-submenu-open">
      <a href="javascript:void(0);" class="ant-menu-submenu-title">
        <i nz-icon="" class="anticon anticon-"></i>
        <span>Try-on</span>
        <i class="ant-menu-submenu-arrow"></i>
      </a>
      <ul class="ant-menu ant-menu-inline ant-menu-sub dropdown-menu" style="display: block;">
        <li [ngClass]="{'active': itemConditions['Image capturing']}" class="ant-menu-item">
          <a class="p-l-30" href="">
            
            <span>Image Capturing</span>
          </a>
        </li>
        <li [ngClass]="{'active': itemConditions['Necklace Selection']}" class="ant-menu-item">
          <a class="p-l-30" href="">
            <span>Necklace Selection</span>
          </a>
        </li>
        <li [ngClass]="{'active': itemConditions['Clothing Generation']}" class="ant-menu-item">
          <a class="p-l-30" href="">
            <span>Clothing Generation</span>
          </a>
        </li>
        <li [ngClass]="{'active': itemConditions['Background Enhancement']}" class="ant-menu-item">
          <a class="p-l-30" href="">
            <span>Background Enhancement</span>
          </a>
        </li>
      </ul>
    </li>
  </ul>
  

    
 
</perfect-scrollbar>    